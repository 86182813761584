import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MEDIA, COLORS } from '../utils/styles'

const ScWrapper = styled.div`
  background-color: ${COLORS.BLACK_BG};
`

const ScSeparatorImage = styled(GatsbyImage)`
  width: auto;
  height: 90px;

  ${MEDIA.TABLET`
    height: 70px;
  `}
  ${MEDIA.MOBILE`
    height: 35px;
  `}
`

const ScTitle = styled.h2`
  font-family: var(--font-cormorantSc);
  text-align: center;
  font-size: var(--text-title);
  font-weight: var(--extrabold);
  line-height: var(--solid);
  margin: 40px 0;
  ${MEDIA.MOBILE`
    margin: 20px 0;
  `}
`

const ScItemWrapper = styled.div`
  padding-bottom: 120px;
  ${MEDIA.MOBILE`
    padding-bottom: 80px;
  `}
`
const ScItem = styled.div`
  position: relative;
  height: 200px;
  margin-top: 50px;
  ${MEDIA.MOBILE`
    margin-top: 30px;
  `}
`
const ScBackgroundWrapper = styled.div`
  position: absolute;
  width: 80%;
  height: 100%;
  top: 0;
  left: 0;

  ${(props) =>
    props.isRight &&
    `
    left: unset;
    right: 0;
  `}
`
const ScBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: ${COLORS.ORANGE};
  opacity: 0.9;
  ${(props) =>
    props.isRight &&
    `
    background-color: white;
    opacity: 0.7;
  `}
`
const ScBackgroundImage = styled(GatsbyImage)`
  width: 100%;
  height: inherit;
`
const ScItemLayout = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  z-index: 2;
  max-width: var(--size-max-width);
  margin: 0 auto;
  padding: 10px var(--size-gutter);
  color: white;

  ${(props) =>
    props.isRight &&
    `
    flex-direction: row-reverse;
  `}
`

const ScLocationImage = styled(GatsbyImage)`
  height: 180px;
  width: 70%;
  border-radius: 10px;
  ${MEDIA.MOBILE`
    width: 60%;
  `}
  box-shadow: 3px 3px 5px black;
`
const ScDetailFlex = styled.div`
  width: 30%;
  ${MEDIA.MOBILE`
    width: 40%;
  `}
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 5px 0 0;
  ${(props) =>
    props.isRight &&
    `
    align-items: flex-end;
    margin: 0 0 0 5px;
  `}
  justify-content: center;
  color: black;
`

const ScLocationTitle = styled.h3`
  font-weight: bold;
  margin: 16px 0 0 0;
  ${(props) =>
    props.isRight &&
    `
    text-align: right;
  `}

  ${MEDIA.SMALLEST`
    font-size: 15px;
  `}
`

const ScLocationDescription = styled.div`
  font-weight: bold;
  font-size: 16px;
  max-width: 215px;
  ${MEDIA.TABLET`
    font-size: 14px;
    max-width: 170px;
  `}
  ${MEDIA.MOBILE`
    font-size: 12px;
  `}

  ${(props) =>
    props.isRight &&
    `
    text-align: right;
  `}
`

const LocationItem = ({ locations }) => {
  return (
    <ScItemWrapper>
      {locations.map((location, index) => {
        const isRight = !!(index % 2)
        return (
          <ScItem key={location.name}>
            <ScBackgroundWrapper isRight={isRight}>
              <ScBackground isRight={isRight} />
              <ScBackgroundImage
                image={location.image.gatsbyImageData}
                alt={location.image.title}
              />
            </ScBackgroundWrapper>
            <ScItemLayout isRight={isRight}>
              <ScDetailFlex isRight={isRight}>
                <ScLocationTitle isRight={isRight}>
                  {location.name}
                </ScLocationTitle>
                <ScLocationDescription
                  isRight={isRight}
                  dangerouslySetInnerHTML={{
                    __html: location.description.childMarkdownRemark.html,
                  }}
                />
              </ScDetailFlex>
              <ScLocationImage
                image={location.image.gatsbyImageData}
                alt={location.image.title}
              />
            </ScItemLayout>
          </ScItem>
        )
      })}
    </ScItemWrapper>
  )
}

const Location = ({ separatorImage, locations }) => {
  return (
    <ScWrapper>
      <ScSeparatorImage
        image={separatorImage.gatsbyImageData}
        alt={separatorImage.title}
      />

      <ScTitle>OUR LOCATION</ScTitle>
      <LocationItem locations={locations} />
    </ScWrapper>
  )
}

export default Location
