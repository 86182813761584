import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MEDIA, COLORS } from '../utils/styles'

const ScBackgroundImage = styled.div`
  position: relative;
  height: 700px;
  ${MEDIA.TABLET`
    height: 500px;
  `}
  ${MEDIA.MOBILE`
    height: 300px;
  `}
`

const ScGatsbyImage = styled(GatsbyImage)`
  width: auto;
  height: 700px;
  img {
    object-position: top left;
  }

  ${MEDIA.TABLET`
    height: 500px;
  `}
  ${MEDIA.MOBILE`
    height: 300px;
  `}
`

const ScDetailWrapper = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
`

const ScDetail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  padding: var(--space-2xl) var(--size-gutter);
  ${MEDIA.DESKTOP`
    width: var(--size-max-width);
    margin: 0 auto;
  `}
`

const ScText = styled.div`
  font-family: var(--font-cormorantSc);
  width: 55%;
  letter-spacing: -0.012em;
  font-size: 34px;
  font-weight: var(--extrabold);
  line-height: var(--default);
  padding: 10px 40px;
  ${MEDIA.TABLET`
    width: 45%;
    font-size: 20px;
  `}
  ${MEDIA.MOBILE`
    width: 55%;
    font-size: 16px;
    line-height: var(--dense);
    padding: 0 20px;
  `}
  background-color: ${COLORS.ORANGE_TRS};
  color: black;
`

function AboutUs({ image, content }) {
  return (
    <ScBackgroundImage>
      <ScGatsbyImage image={image.gatsbyImageData} alt={image.title} />
      <ScDetailWrapper>
        <ScDetail>
          <ScText
            dangerouslySetInnerHTML={{
              __html: content.childMarkdownRemark.html,
            }}
          />
        </ScDetail>
      </ScDetailWrapper>
    </ScBackgroundImage>
  )
}

export default AboutUs
