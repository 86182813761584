import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import Footer from '../components/footer'
import Header from '../components/homePage/header'
import Location from '../components/homePage/location'
import AboutUs from '../components/homePage/aboutUs'
import RecommendedMenu from '../components/homePage/recommendedMenu'

class RootIndex extends React.Component {
  render() {
    const home = get(this, 'props.data.contentfulHome')
    const recommendedMenu = home.recommendedMenu

    return (
      <Layout title="The Fat Cow Home Page" location={this.props.location}>
        <Header image={home.headerImage} title={home.name} />
        <RecommendedMenu menus={recommendedMenu} />
        <Location
          separatorImage={home.separatorImage}
          bgImage={home.headerImage}
          locationImage={home.aboutUsImage}
          locations={home.locations}
        />

        <AboutUs image={home.aboutUsImage} content={home.aboutUs} />
        <Footer />
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    contentfulHome(contentful_id: { eq: "6uAUZR10UxlXBcl5oTkzs" }) {
      name
      headerImage {
        title
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: TRACED_SVG
          quality: 100
          formats: WEBP
        )
      }
      separatorImage {
        title
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: TRACED_SVG
          quality: 80
          formats: WEBP
        )
      }
      recommendedMenu {
        active
        name
        slug
        price
        ingredient
        image {
          title
          gatsbyImageData(
            placeholder: BLURRED
            width: 300
            formats: WEBP
            quality: 80
          )
        }
      }
      aboutUs {
        childMarkdownRemark {
          html
        }
      }
      locations {
        name
        image {
          title
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            formats: WEBP
          )
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
      aboutUsImage {
        title
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          quality: 80
          formats: WEBP
        )
      }
    }
  }
`
