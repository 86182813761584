import React from 'react'
import { Link } from 'gatsby'
import Container from './container'
import * as styles from './footer.module.css'

const Footer = () => (
  <Container as="footer">
    <div className={styles.container}>
      Life is too short to drink bad beer! &middot;{' '}
      <Link to={`/menu`} rel="next">
        MENU
      </Link>
    </div>
  </Container>
)

export default Footer
