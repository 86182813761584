import React, { useContext } from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MEDIA, COLORS } from '../utils/styles'
import MatchMediaContext from 'react-match-media-context'

const ScWrapper = styled.div`
  background-color: ${COLORS.WHITE_BG};
  padding-bottom: 50px;
`

const ScLayout = styled.div`
  max-width: var(--size-max-width);
  margin: 0 auto;
  padding: var(--space-2xl) var(--size-gutter);
  color: white;
`

const ScTitle = styled.h2`
  font-family: var(--font-cormorantSc);
  color: ${COLORS.ORANGE};
  letter-spacing: -0.012em;
  font-size: var(--text-title);
  font-weight: var(--extrabold);
  line-height: var(--solid);
  margin: 40px 0;
  ${MEDIA.MOBILE`
    margin: 20px 0;
  `}
`

const ScMenuGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  ${MEDIA.TABLET`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${MEDIA.MOBILE`
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  `}
`

const ScMenuItem = styled.div`
  color: black;
  position: relative;
  background-color: ${COLORS.MENUITEM_BG};
  border-radius: 10px;
  box-shadow: 3px 3px 5px ${COLORS.GREY};
  overflow: hidden;
  height: 310px;
  ${MEDIA.MOBILE`
    height: 300px;
  `}
`

const ScMenuImage = styled(GatsbyImage)`
  height: 150px;
  width: auto;
  box-shadow: 2px 2px 5px;
  img {
    border-radius: 10px 10px 0 0;
  }
`

const ScMenuDetail = styled.div`
  margin: 15px;
`

const ScMenuTitle = styled.h3`
  color: white;
  font-weight: bold;
  margin: 0 0 10px;
  ${MEDIA.MOBILE`
    font-size: 17px;
  `}

  ${MEDIA.SMALLEST`
    font-size: 13px;
  `}
`

const ScMenuDescription = styled.div`
  font-size: var(--text-xs);
  color: white;
  ${MEDIA.SMALLEST`
    font-size: 11px;
  `}
`

const ScMenuPrice = styled.div`
  font-weight: bold;
  font-size: var(--text-md);
  color: ${COLORS.ORANGE};
  position: absolute;
  bottom: 20px;
  ${MEDIA.MOBILE`
    font-size: 17px;
    bottom: 15px;
  `}
  ${MEDIA.SMALLEST`
    font-size: 15px;
    bottom: 10px;
  `}
`

const RecommendedMenu = ({ menus }) => {
  const { isTablet, isDesktop } = useContext(MatchMediaContext)
  const filteredMenus = menus.slice(0, isDesktop ? 10 : isTablet ? 9 : 8)
  return (
    <ScWrapper>
      <ScLayout>
        <ScTitle>RECOMMENDED MENU</ScTitle>
        <ScMenuGrid>
          {filteredMenus.map((menu) => {
            return (
              <ScMenuItem key={menu.name}>
                <ScMenuImage
                  alt={menu.name}
                  image={menu.image.gatsbyImageData}
                />
                <ScMenuDetail>
                  <ScMenuTitle>{menu.name}</ScMenuTitle>
                  <ScMenuDescription>{menu.ingredient}</ScMenuDescription>
                  <ScMenuPrice>{menu.price} THB</ScMenuPrice>
                </ScMenuDetail>
              </ScMenuItem>
            )
          })}
        </ScMenuGrid>
      </ScLayout>
    </ScWrapper>
  )
}

export default RecommendedMenu
